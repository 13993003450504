import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ac83bf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-box" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "sub-text" }
const _hoisted_4 = { class: "sub-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_progress, {
      type: "circle",
      percentage: _ctx.percenShipping,
      color: "#5cb87a",
      width: 200,
      class: "progress-content"
    }, {
      default: _withCtx(({ percentage }) => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(percentage) + "%", 1)
      ]),
      _: 1
    }, 8, ["percentage"]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_3, "กรุณารอสักครู่ ระบบกำลังสร้าง" + _toDisplayString(_ctx.type), 1),
      _createElementVNode("p", _hoisted_4, "จำนวน " + _toDisplayString(_ctx.countOrder) + "/" + _toDisplayString(_ctx.totalOrder) + " ออเดอร์", 1)
    ])
  ]))
}