
import { computed, defineComponent } from 'vue'

export default defineComponent({
	name: 'ProgressLoading',
	props: {
		countOrder: {
			type: Number,
			default: 0
		},
		totalOrder: {
			type: Number,
			default: 0
		},
		type: {
			type: String
		}
	},
	setup(props) {
		const percenShipping = computed(() => {
			return Math.floor((props.countOrder / props.totalOrder) * 100)
		})
		return {
			percenShipping
		}
	}
})
